import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'fr',
		resources: {
			fr: {
				translations: require('../locales/fr.json')
			},
			en: {
				translations: require('../locales/en.json')
			}
		},
		ns: ['translations'],
		defaultNS: 'translations',
		returnObjects: true,
		debug: process.env.NODE_ENV === 'development',
		keySeparator: false,
		react: {
			wait: true,
		},
		detection: {
			order: ['path'],
		}
	});

i18next.languages = ['fr'];

export default i18next;